import { joinURL, withBase } from 'ufo'

import type { PublicImage } from '@/service/__generated-api'
import type { PartialExcept } from '@/types/utility'

function urlSafeBase64(value: string) {
  const base64 = process.client
    ? window.btoa(value)
    : Buffer.from(value).toString('base64')

  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export const getThumb = (
  image: PartialExcept<PublicImage, 'file' | 'ppoi_tuple'>,
  width?: number | null,
  height?: number | null,
  resize: 'fit' | 'fill' | 'auto' = 'fill',
  format = '',
  enlarge: 0 | 1 = 1
  // eslint-disable-next-line max-params
): string => {
  if (!image?.file) {
    return ''
  }

  const encodedUrl = `/${urlSafeBase64(image.file)}`
  const extension = format ? `/ext:${format}` : ''
  const [x, y] = image.ppoi_tuple
  const gravity = `/g:fp:${x}:${y}`
  const resizing =
    width && height
      ? `/rs:${resize}:${Math.round(width)}:${Math.round(height)}:${enlarge}`
      : ''

  const path = `${resizing}${gravity}${extension}${encodedUrl}`
  const baseURL = import.meta.env.VITE_IMGPROXY_URL
  return withBase(joinURL('_', path), baseURL)
}
